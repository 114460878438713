import { InteractiveComponents } from '../../../core/enums';
import { Locale, LocaleKeys } from '../../../core/locale';
import { ScreenTaskControllerTypes } from '../../../core/navigation/ScreenTaskControllerTypes';
import { Storage } from '../../../core/storage/Storage';
import { CcbSwitch } from '../../../core/utils/ccb';
import { CcbSwitchName } from '../../../core/utils/ccb/enums';
import { setChannelsTypeAction } from '../../overlay/channeloptionsoverlay/channeloptionsoverlay.actions';
import {
    GLOBAL_MOUSE_TRAP,
    showGuideOptionsOverlay,
} from '../../overlay/channeloptionsoverlay/channeloptionsoverlay.reducers';
import {
    hideOverlayDialog,
    onKeyDownOverlayDialog,
    onKeyLeftOverlayDialog,
    onKeyRightOverlayDialog,
    onKeyUpOverlayDialog,
    onMouseOutOverlayDialogOperations,
    onMouseOverOverlayDialogOperations,
} from '../../overlaydialog/overlaydialog.reducers';
import { showGuideScreen } from '../../screens/screens.reducers';

const mHorizontalSelectionOptionIndex = () => [
    LocaleKeys.GUIDE_CHANNEL_TYPES_MY_CHANNELS,
    ...(CcbSwitch.getInstance().isEnabledAndOverriden(
        CcbSwitchName.ENABLE_FAVORITES_CHANNELS_REMOVE_IN_KST_5397,
    )
        ? [LocaleKeys.GUIDE_CHANNEL_TYPES_FAVORITES]
        : []),
    LocaleKeys.GUIDE_CHANNEL_TYPES_ALL_CHANNELS,
];

export const onKeyOkEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const {
        verticalPosition: overlayHighlightVerticalPosition,
        horizontalPosition: overlayHighlightHorizontalPosition,
    } = getState().overlayHighlight;
    const { screenTaskController } = getState().screens;

    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }

    const selectedChannelsTypeIndex =
        mHorizontalSelectionOptionIndex()[
            overlayHighlightHorizontalPosition[overlayHighlightVerticalPosition]
        ];

    Storage.getInstance().setItem(Storage.Keys.CHANNELS_TYPE, selectedChannelsTypeIndex);
    dispatch(
        setChannelsTypeAction(
            Locale.getString(LocaleKeys[selectedChannelsTypeIndex]),
            selectedChannelsTypeIndex,
        ),
    );
    dispatch(hideOverlayDialog());
    dispatch(showGuideScreen());
};

export const onLongKeyOkEmptyGuide = () => (dispatch: Function) => {
    dispatch(showGuideOptionsOverlay());
};

export const onKeyUpEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyUpOverlayDialog());
};

export const onKeyDownEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyDownOverlayDialog());
};

export const onKeyRightEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyRightOverlayDialog());
};

export const onKeyLeftEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyLeftOverlayDialog());
};

export const onKeyBackEmptyGuide = () => (dispatch: Function) => {
    dispatch(hideOverlayDialog());
};

export const onMouseOverEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onMouseOverOverlayDialogOperations());
};

export const onMouseOutEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onMouseOutOverlayDialogOperations());
};

export const onMouseClickEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { activeComponent } = getState().interactiveLayer;
    const { value } = getState().inputFactory;
    const { screenTaskController } = getState().screens;

    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }

    if (value === GLOBAL_MOUSE_TRAP) {
        dispatch(hideOverlayDialog());
        return;
    }

    if (activeComponent === InteractiveComponents.WEST) dispatch(onKeyLeftOverlayDialog());
    else if (activeComponent === InteractiveComponents.EAST) dispatch(onKeyRightOverlayDialog());
    else dispatch(onKeyOkEmptyGuide());
};
